
.mobile-menu {
    
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    margin: 0.4rem 0 0.2rem 0;
    align-items: center;
  
    display: flex;
    
    gap: 0.5rem;

    background-color: #0A2C47;
    padding: 0.5rem;
    
}

.desktop-menu {
    display: flex;
  
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center; 
    margin: 0.4rem 0 0.2rem 0;
    align-items: center;
}

.menu-button {
    font-size: 1.2rem;
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    padding: 0;
    margin: 0;
    width: 45px;
}

@media only screen and (max-width: 470px) {
    .desktop-menu {
        justify-content: space-around;
    }

    .menu-icon {
        display: flex;
    }
    
}