.mapboxgl-popup-content {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    padding: 0!important;
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px #00000082;
    min-width: 230px;
    background: #fff;
    color: #444444;
}

.cr-popup-content {
    display: flex;
    text-align: start;
    flex-direction: column;
}

.cr-popup-content-bus-stop {
    display: flex;
    text-align: start;
    flex-direction: column;
    min-width: 100%;

}
.cr-popup-body-temp {
    display: flex;
    text-align: start;
    padding: 1rem;
    padding-bottom: 0.5rem;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 2rem 1rem 1rem 1rem;
    background: url("./../images/bade-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: inset 0 0 0 2000px rgb(255 255 255 / 77%);
}

.cr-temp-text {
    font-size: 1.5rem;
    font-weight: 600;
}

.cr-popup-header {
    display: flex;

    flex-direction: column;
    background-color: #0A2C47;
    color: #fff;
    padding: 1rem;
    border-radius: 10px 10px 0 0;
    box-shadow: 0px 3px 7px #00000026;
    z-index: 9;
}

.cr-popup-header p {
    font-weight: 400;
    margin: 0.2rem 0rem 0.2rem 0rem;
    color: #fff;
    text-shadow: 0 0 9px #0000009c;
}

.cr-popup-header h3 {
    margin: 0;
    padding: 0;
    color: #fff;
    text-shadow: 0 0 9px #0000009c;
    line-height: 1.4rem;
    font-size: 1rem;
}

.cr-popup-meta {
    font-weight: 300;
    margin: 0.2rem 0rem 0.2rem 0rem;
}

.cr-popup-status {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #00a575;
    
    padding: 1rem;
    height: 0.8rem;
}

.cr-popup-status p {
    font-weight: 300;
    margin: 0.2rem 0rem 0.2rem 0rem;
    color: #fff;
}

.cr-popup-body {
    display: flex;
    text-align: start;
    padding: 1rem;
    padding-bottom: 0.5rem;
    flex-direction: column;
    align-items: flex-start;
}

.cr-popup-body h3 {
    font-size: 0.9rem;
    margin: 0 0 0.5rem 0;
    padding: 0;
    color: #0A2C47;
    font-weight: 600;
}

.pop-up-logos {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mapboxgl-popup-close-button {
    font-size: 25px;
    color: #fff;
    z-index: 11;
}

.cr-popup-footer {
    display: flex;

    padding: 1rem;
    flex-direction: column;

    background-color: #0A2C47;
    
    padding: 0.5rem;

}

.cr-popup-footer p {
    color: #fff;
    text-shadow: 0 0 9px #0000009c;
    text-align: center;
}

.cr-popup-departure-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: #fff;
    width: 100%;
    gap: 0.5rem;
}

.cr-popup-departure-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #e5e5e5;
    gap: 0.5rem;
}

.cr-popup-departure-item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 33%;
}

.cr-popup-departure-line {
    flex: 1 1;
    min-width: 35px;
    background-color: #0A2C47;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
}

.cr-popup-departure-line p{
    font-size: 0.8rem;
    font-weight: 500;
    color: #fff;
}

.cr-popup-departure-line-name p, .cr-popup-departure-time p {
    font-size: 0.8rem;
    font-weight: 500;
}

.cr-popup-departure-line-name {
    font-size: 0.8rem;
    font-weight: 300;
    color: #fff;
    flex: 4;
    min-width: 35px;
}

.cr-popup-departure-line-time {
    font-size: 0.8rem;
    font-weight: 300;
    color: #fff;
    flex: 1;
    min-width: 35px;
}


.mapboxgl-popup {
    display: flex;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    will-change: transform;
    min-width: 250px;
}

@media only screen and (max-width: 600px) {

    .mapboxgl-popup-close-button {
        padding: 1rem 0.5rem 1rem 1rem;
    }

    .cr-popup-header h3 {
        font-size: 1rem;
    }

    .cr-popup-meta {
        font-size: 0.8rem;
    }


}