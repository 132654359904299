
.audio-recorder {
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.audio-recorder-btn {
    background-color: #0A2C47;
    border: none;
    padding: 8px 10px;

    border-radius: 5px;
    cursor: pointer;

    transition: background-color 0.3s ease;
}

.audio-recorder-active button:hover {
    background-color: #740001;
}

.audio-recorder-active,  .audio-recorder-active button:active {
    background-color: #740001;
}

.audio-recorder-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.audio-recorder-btn img {
    width: 25px;
}


@media only screen and (max-width: 470px) {
    .audio-recorder-btn img {
        width: 20px;
    }

    .audio-recorder-btn {

        padding: 0.5rem;
        border-radius: 5px;
    
        transition: background-color 0.3s ease;
    }
}