.share-link-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000000cc;
    display: none; /* Start hidden */
    justify-content: center;
    align-items: center;
    z-index: 99;
}

.share-link-modal {
    background: white;
    
    border-radius: 5px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    width: 90%;
    max-width: 500px;
    z-index: 100;
    position: relative;
}

.share-link-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #0A2C47;
}

.share-link-modal-header h1 {
    color: white;
    margin: 0;
    padding: 10px;
}

.close-button {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.share-link-modal-content {
    padding: 0.5rem;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: #e1e1e159;
}

.share-link-modal-content button {
    margin-top: 10px;
}

.share-link-modal-btn-container {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin: 0.5rem;
}

.share-link-modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    background-color: #0A2C47;
}

.share-link-modal-btn {
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}
