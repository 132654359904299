
.consent-banner-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #333;
    color: #fff;
    text-align: center;
    z-index: 1000;
  
  }

    .consent-banner {
        display: flex;
        margin: 0.5rem;
        flex-direction: column;
        gap: 0.5rem;

    }
  .consent-banner p {
    margin: 0;
    display: inline;
    color: white;
  }

  .consent-banner a {
    color: #fff;
    text-decoration: underline;
  }

  .consent-banner-button-container {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    margin: 0.5rem;
  }

  .consent-banner button {
    margin-left: 10px;
    padding: 5px 10px;
    background: #fff;
    color: #333;
    border: none;
    cursor: pointer;
  }
  