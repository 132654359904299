.situation-messages-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    
    margin-top: 2rem;
  }

  .situation-messages-filter-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .situation-messages-filter-group {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .situation-list-container {
    display: flex;
    flex-direction: column;
    
    width: 80%;
  }

  .situation-item {
    margin-bottom: 10px;
    padding-bottom: 10px;
  }

  .situation-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #0A2C47;
  }

  
  .situation-body {
    display: flex;
    flex-direction: column;
    background-color: #f1f1f1;
    padding: 0 1rem 1rem 1rem;
  }

  .situation-affects {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
  }


  .situation-severity {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0055ff;
    padding: 0.5rem;
    width: 10px;
    }

    .situation-severity-noImpact {
    background-color: #37c400;
    }

    .situation-severity-slight {
    background-color: #d8c200;
    }

    .situation-severity-normal {
    background-color: #c77700;
    }

    .situation-severity-danger {
    background-color: #ff0000;
    }



    .situation-header-title {
    font-size: 1.2rem;
    font-weight: bold;
    
    }

    .situation-header-meta {
    display: flex;
    gap: 1rem;
 

    }

    .situation-header-meta p {
        color: white;
        margin: 0.3rem;
    }


  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .page-item {
    margin: 0 5px;
    padding: 5px 10px;
    border: none;
    background-color: #0A2C47;
    color: white;
    cursor: pointer;
  }
  
.page-item.active, .page-item:hover {
    background-color: #4b505a;
  }
  
  .page-item.disabled {
    background-color: #ccc;
    cursor: default;
  }
  
  .quay-list, .line-list {
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    margin-top: 8px;
  }
  
.quay-item, .line-item {
    padding: 8px 10px;
    cursor: pointer;
  }
  
  .quay-item:hover, .line-item:hover {
    background-color: #ececec;
  }

  @media only screen and (max-width: 470px) {
    .situation-messages-page {
        margin-top: 1rem;
    }
  }