:root {
    --bg-color-main: #19202F;
    --bg-color-secondary: #1E2738;
    --text-color-main: #B1B1B5;
    --border-color-main: #B1B1B5;
    --green-color: #00C689;
    --blue-color: #3DA5F4;
    --red-color: #F1536E;
    --orange-color: #FDA006;
    --bs-border-color-translucent: rgb(255 255 255 / 18%);
}

main, #root, .App {
  	display: flex;
    flex-direction: column;
    height: 100%;
}

.backend-container {
  	background-color: var(--bg-color-main);
    color: var(--text-color-main);
    color: var(--text-color-main);
    height: 100%;
}

.backend-header {
  	display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    background-color: #1e2738;
    padding: 1rem;
    border-radius: 10px 10px 0 0;
    gap: 1rem;
    flex-wrap: wrap;
}

.backend-header h1 {
  	font-family: "Megrim", system-ui;
	font-weight: 400;
	font-style: normal;
	margin:0;
	color: white;
	font-size: 3rem;
}

.backend-header-clock {
	color: white;
	font-size: 1.5rem;
}

.backend-header a:hover {
  	border: none;
}

.chart-row {
	margin: 2rem;
	flex-direction: column;
	display: flex;
	gap: 1rem;
	flex: 2;
	flex-wrap: wrap;
}
  
.form-group {
 	 margin-bottom: 10px;
}

.log-container {
	display: flex;
	flex-direction: column;
	height: 300px;
	width: 100%;
	overflow-y: scroll;
	background-color: var(--bg-color-secondary);
	border-radius: 5px;
	flex: 2;
}

.backend-situation-messages {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin: 0 2rem;
	max-height: 600px;
	overflow-y: auto;
	flex: 1;
  
}

.backend-item-container {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 2rem auto;
}

.chart-card {
    background-color: var(--bg-color-secondary);
    border-radius: 5px;
    width: 50%;
    border: solid 1px #a5a5a53d;
}

.chart-card-body {
    margin:0.3rem;
}

.chart-card-content {
    margin: 0.5rem;
}

.chart-card-title {
    color: white;
}

.chart-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    gap: 1rem;
}

.mapboxgl-ctrl.mapboxgl-ctrl-attrib {
    display: none;
}

/* chart details */

.chart-details {
    background-color: var(--bg-color-secondary);
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.chart-details-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.chart-details-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

  /* overrides */

.btn-primary {
    --bs-btn-color: #fff;
    --bs-btn-bg: #405189;
    --bs-btn-border-color: #405189;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #405189;
    --bs-btn-hover-border-color: #405189;
    --bs-btn-focus-shadow-rgb: 49,132,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #405189;
    --bs-btn-active-border-color: #405189;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #405189;
    --bs-btn-disabled-border-color: #405189;
}

#log-container {
    display: flex;
    flex-direction: column;
    background-color: var(--bg-color-secondary);
    max-height: 250px;
    min-height: 250px;
    overflow-y: auto;
}

.log-entry {
    color: white;
    padding: 0 1rem;
    margin-bottom: 10px;
    font-family: monospace;
}
  
.loading-indicator {
    height: 10px;
    background-color: gray;
    overflow: hidden;
}
  
.loading-bar {
    height: 100%;
    width: 100%;
    background-color: green;
    animation: loading 1s linear infinite;
}
  
@keyframes loading {
    0% {
      width: 0%;
    }
  
    100% {
      width: 100%;
    }
}
  

.situationItem {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-bottom: 1px solid var(--bs-border-color-translucent);
    background-color: var(--bg-color-secondary);
}

.situation-item-meta {
    display: flex;
    justify-content: space-between;
	width: 100%;
}

.backend-map-container {
    display: flex;
    gap: 1rem;
    max-height: 300px;
    overflow-y: auto;
    flex: 2 1;
}

.backend-sidebar {
	display: flex;
	flex-direction: column;
	margin: 0.5rem;
	flex: 1;
	overflow-y: auto;
}

.backend-sidebar-title {
    color: white;
    font-size: 1.5rem;
    margin: 0.5rem;
}

.backend-sidebar-body-text {
	color: white;
	font-size: 0.8rem;
}

.backend-sidebar-item {
	display: flex;
	justify-content: space-between;
	padding: 0.3rem;
}

.backend-sidebar-item:hover {
	background-color: var(--bg-color-secondary);
	cursor: pointer;
}

.message {
    transition: all 0.3s ease-out;
    opacity: 0;
    transform: translateY(20px);
    animation: slideIn 0.3s forwards;
}

@keyframes slideIn {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}