.about-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 2rem;
}

.about-page-content {
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 800px;
    margin-top: 1rem;
    margin: auto;
}

.about-page-content h1, .about-page-content h2 {

    margin-top: 2rem;
}

.about-link {
    font-family: unset;

}

.about-link:hover {
    border-bottom: none;
    text-decoration: underline;
}

@media only screen and (max-width: 470px) {
    .about-page {
        margin-top: 1rem;
    }
}