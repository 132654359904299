.travel-planner-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    
    margin-top: 2rem;
}

.tp-ai-description {
    transition: opacity 0.5s ease-out;
    opacity: 1;
    text-align: center;
    
    margin: 0 1rem;
}
  
.tp-ai-description.hide {
    opacity: 0;
    pointer-events: none;
    height: 0;
    overflow: hidden;
}

.tp-notice {
    margin: 1rem;
}

.travel-planner-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    
    margin-top: 1rem;
    margin: auto;
}

.travel-planner-output {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.travel-planner-output h2 {
    font-size: 1.2rem;
}

input[type="text"], input[type="number"], input[type="email"], input[type="password"], input[type="date"], input[type="time"], input[type="tel"], input[type="url"], input[type="search"], input[type="number"], input[type="range"], input[type="color"], input[type="radio"], select, textarea{
    width: 100%;
    padding: 10px 19px;
    
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    color: #0A2C47;
}

select {
    width: 100%;
    padding: 0.2rem;
    margin: 0 0.4rem 0 0.4rem;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    color: #0A2C47;
}

.react-datepicker-wrapper .react-datepicker__input-container input[type="text"] {
    font-size: 1rem;
    font-weight: 400;
    font-family: 'montserrat', sans-serif;
    color: #0A2C47;
    padding: 0.2rem;
    border: 0;
    border-bottom: 2px solid #0A2C47;
    margin: 0 0.4rem 0 0.4rem;
    width: unset;
    max-width: 140px;
    border-radius: 0;
}

input[type="time"] {
    font-size: 1rem;
    font-weight: 400;
    font-family: 'montserrat', sans-serif;
    color: #0A2C47;
    padding: 0.2rem;
    border: 0;
    border-bottom: 2px solid #0A2C47;
    margin: 0 0.4rem 0 0.4rem;
    width: unset;
    max-width: 140px;
    border-radius: 0;
}

input[type="time"]:hover, .date-picker:hover {
    cursor: pointer;
}

button, input[type="submit"] {
    background-color: #0A2C47;
    color: white;
    padding: 14px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover, input[type="submit"]:hover {
    background-color: #071f33;
}

.btn-green {
    background-color: #226141;
    color: white;
    padding: 14px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;

}

.btn-green:hover {
    background-color: #3a8f5c;
}


.travelplaner-row {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgb(240, 240, 240);
    margin-top: 1rem;
    font-size: 14px;
}

.travelplaner-meta-container {
    display: flex;
    width: 100%;
    background-color: #0A2C47;
    color: white;
    flex-wrap: wrap;
    border-radius: 3px 3px 0 0;
}

.travelplaner-from-container, .travelplaner-to-container {
    display: flex;
    margin: 0.7rem;
    gap: 1rem;
    flex: 2;
}

.travelplaner-from-container p, .travelplaner-to-container p {
    text-align: left;
    margin-block-end: 0.2rem;
    color: white;
}


.travelplaner-departure-from-to {
    display: flex;
    width: 100%;
}

.travelplaner-departure-time, .travelplaner-departure-from-to {
    display: flex;
    gap: 0.5rem;
}

.travelplaner-from-label, .travelplaner-to-label, .travelplaner-start-label, .travelplaner-end-label {
    margin-block-end: 0rem;
    margin-block-start: 0;
    line-height: 16px;
    font-size: 13px;
  
}

.travelplaner-from-time, .travelplaner-to-time {
    display: flex;
    flex-direction: column;

}

.travelplaner-from, .travelplaner-to, .travelplaner-start, .travelplaner-end, .travelplaner-from-name, .travelplaner-to-name {
    margin-block-end: 0rem;
    margin-block-start: 0;
    font-size: 1rem;
    
}

.travelplaner-start, .travelplaner-end {
    font-weight: 500;
    margin-block-end: 0rem;
    margin-block-start: 0;
}

.input-top {
    display: flex;
    width: 100%;
    max-width: 900px;
    margin: auto;
    justify-content: center;
  }

  .input-top form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    border-radius: 10px;
    width: 100%;
  }
  
  .trip-detail, .leg-detail {
    margin: 2rem 0;
  }

  .trip-detail {
    box-shadow: 0 0 20px 3px #5150502b;
  }
  
  .leg-detail div {
    margin: 5px 0;
  }

  .leg-detail, .trip-times {
    display: none;
  }

  .travel-planner-details-btn {
    padding: 14px 20px;
    /* margin: 0 20% 8px 20%; */
    border: none;
    background-color: #dddddd;
    /* border-radius: 0 0 15px 15px; */
    cursor: pointer;
    font-weight: 500;
    /* border-left: 5px solid #0A2C47; */
    /* border-right: 5px solid #0A2C47; */
    border-bottom: 2px solid #0A2C47;
    /* box-shadow: 0px 0px 10px 0px #00000030; */
}

.travelplaner-all-container {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #0A2C4730;
    border-left: 1px solid #0A2C4730;

}


.travelplaner-legs-container {
    display: flex;
    padding: 1rem;
    transition: all 1s ease;
    align-items: flex-end;
    flex-wrap: wrap;
    
}


.travelplaner-leg-child {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
    transition: all 2s ease;
    min-width: 250px;
  }

  .travelplaner-leg-child-end {
    display: flex;
    flex-direction: column;
    flex: 1;
    transition: all 2s ease;
  }

  .travelplaner-leg-child-start {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    transition: all 2s ease;
  }



.travelplaner-trip-details-left-time {
    margin-bottom: 0.2rem;
    font-weight: 600;
    left: -1.5rem;
    top: 0;
}

.travelplaner-trip-details-left-time-start {
    margin-bottom: 0.2rem;
    font-weight: 600;
    left: 0;
    top: 0;
}

.travelplaner-trip-details-right-time {
    margin-bottom: 0.2rem;
    right: 0;
    position: absolute;
    font-weight: 600;

}

.leg-indicator {
    display: flex;
    align-items: center;
    width: 100%!important;
    text-align: center;
    border-radius: 10px;
    position: relative;
    justify-content: space-between;
}

.leg-indicator:before {
    position: absolute;
    height: 6px;
    background: rgb(0, 0, 0);
    content: "";
    width: 100%;
  }
 
  .leg-indicator-dot {
    background-color: #f0c430;
    border-radius: 50%;
    z-index: 1;
    display: inline-block;
    border: 6px solid #0A2C47;
  }

  .leg-indicator-dot-start {
    justify-content: flex-start;
    align-items: center;
    width: 100%!important;
    text-align: center;
    border-radius: 10px;
    position: relative;
   
}

.leg-indicator-dot-end {
    align-items: center;
    width: 100%!important;
    text-align: center;
    border-radius: 10px;
    position: relative;
    justify-content: space-between;
    margin-right: 1rem;
}

.leg-indicator-dot-end:before {
    position: absolute;
    height: 6px;
    background: #0A2C47;
    content: "";
    left: 0;
  }

.leg-indicator-dot-start:before {
    position: absolute;
    height: 6px;
    background: #0A2C47;
    content: "";
    right: 0;
  }

  .travelplaner-details-line-number, .tripdetails-stop-icon {
    display: flex;
    font-weight: 500;
    color: #0A2C47;
    align-items: center;
    gap: 0.3rem;
    padding: 0.2rem 0.2rem;
    color: white;
    border-radius: 5px;

}

.tripdetails-stop-icon {
    background: #0055b7;
}

.travelplaner-details-line-number p {
    color: white;
    font-weight: 600;
    margin-block-start: 0.2rem;
    margin-block-end: 0.2rem;
    line-height: 1.2rem;
}


  .leg-indicator-mode-rail:before, .travelplaner-details-line-number-rail, .line-details-card-header-rail, .rail-color {
    background: #00925a;
  }

    .leg-indicator-mode-subway:before, .travelplaner-details-line-number-subway, .line-details-card-header-subway, .subway-color {
        background: #00adb7;
    }



    .leg-indicator-mode-train:before, .travelplaner-details-line-number-train, .line-details-card-header-train, .train-color {
        background: #00adb7;
    }



  .leg-indicator-mode-bus:before, .travelplaner-details-line-number-bus, .line-details-card-header-bus, .bus-color {
    background: #1F7A8C;
  }

  .leg-indicator-mode-water:before, .travelplaner-details-line-number-water, .line-details-card-header-water, .water-color {
    background: #1F7A8C;
  }



    .leg-indicator-mode-foot:before, .travelplaner-details-line-number-foot, .line-details-card-header-foot, .foot-color {
        background: #414647;
    }


    .leg-indicator-mode-tram:before, .travelplaner-details-line-number-tram, .line-details-card-header-tram, .tram-color {
        background: #b7b700;
    }


    .leg-indicator-mode-ferry:before, .travelplaner-details-line-number-ferry, .line-details-card-header-ferry, .ferry-color {
        background: #00b700;
    }


    .leg-indicator-mode-coach:before, .travelplaner-details-line-number-coach, .line-details-card-header-coach, .coach-color {
        background: #00588b;
    }


    .leg-indicator-mode-air:before, .travelplaner-details-line-number-air, .line-details-card-header-air, .air-color {
        background: #b10000;
    }

    .travelplaner-details-line-name  {
        font-weight: 500;
        margin-bottom:0.3rem;
    }

    
    .travelplaner-hidden-details-line-name, .travelplaner-hidden-trip-details-walk  {
        font-weight: 400;
        text-align: left;
    }

    .travelplaner-hidden-line-name-container {
        display: flex;
        flex: 1;
        align-items: center;
        
        gap: 1rem;
    }
    .travelplaner-hidden-trip-details-text-quay {
        text-align: left;
        transition: all 0.5s ease-in;
        
    }
    
    .travelplaner-hidden-details-mode-container {
        display: flex;
        width: 100%;
        gap: 1rem;

    }

    .travelplaner-details-mode-stop-name-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }

    .travelplaner-hidden-line-name-container:hover, .travelplaner-details-mode-stop-name-container:hover {
        cursor: pointer;
    }

    
  .travelplaner-hidden-details-line-number {
    display: flex;
    font-weight: 500;
    color: #0A2C47;
    align-items: center;
    padding: 0.2rem 0.45rem 0.2rem 0.45rem;
    color: white;
    border-radius: 0 5px 5px 0;
    flex-direction: column;

}

.tripdetails-stop-icon {
    display: flex;
    font-weight: 500;
    color: #0A2C47;
    align-items: center;
    padding: 0.2rem 0.2rem;
    color: white;
    border-radius: 5px;
    flex-direction: column;
    margin-left: 0.5rem;
}

.tripdetails-hidden-stop-icon {
    background: #0055b7;
}

.travelplaner-hidden-details-line-number p {
    color: white;
    font-weight: 600;
    margin-block-start: 0.2rem;
    margin-block-end: 0.2rem;
    line-height: 1.2rem;
    order: 1;
}

.travelplaner-hidden-details-line-number img {
    order:2
}

    .travelplaner-trip-details-walk {
        font-weight: 500;
        margin-bottom:0.3rem;
    }

  .leg-indicator-dot-inactive {
    height: 25px;
    width: 25px;
    background-color: #707070;
    border-radius: 50%;
    z-index: 1;
    display: inline-block;
    border: 4px solid black;
  }

  .travelplaner-line-name-container {
    display: flex;
    justify-content: center;
    align-items: flex-end;
   
    gap: 0.5rem;
}

.travelplaner-hidden-details-line-name-container {
    display: flex;
    flex-direction: column;
   justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
}

.travelplaner-hidden-details-leg-duration {
    font-size: 0.8rem!important;
}
.travelplaner-trip-details-icon-container2 {
    display: flex;
    align-items: flex-start;
    font-weight: 500;
    background-color: #f0f0f0;
    box-shadow: 0 0 20px 3px #7575752b;
    border: 1px solid #767c8930;
    margin: 1rem 0;
    border-radius: 5px;
    min-width: 60%;
    flex: 1;
}

.travelplaner-trip-details-icon {
    width: 2rem;
    margin: auto 0;
}




.leg-indicator-middle:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 4px;
    background: #0A2C47;
}

.leg-indicator-middle .leg-indicator-dot {
    position: absolute;
    top: -12px; /* Adjust as needed */
}

.leg-indicator-middle .leg-indicator-dot:first-child {
    left: 0;
}

.leg-indicator-middle .leg-indicator-dot:last-child {
    right: 0;
}

.travelplaner-hidden-details-bar-container {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100%;
}

.travelplaner-hidden-details-bar {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 6px;
    height: 100%;
}

.leg-time-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
    margin-top: 0.2rem;
}

.userQuery {
    display: flex;
    margin: 1rem auto 1.5rem auto;
    font-size: 1rem;
    font-weight: 400;
    font-family: 'montserrat', sans-serif;
    color: #0A2C47;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.userQuery select {

    font-weight: 400;
    font-family: 'montserrat', sans-serif;
    color: #0A2C47;
    border: 0;
    border-bottom: 2px solid #0A2C47;
    margin: 0 0.4rem 0 0.4rem;
    border-radius: 0;
}

.userQuery select:hover, .userQuery select option:hover {
    cursor: pointer;
}



.user-search-form {
    display: flex;
    gap: 0.7rem;
    margin: 0.5rem 0 0.3rem 0;
    flex-wrap: wrap;
    align-items: center;
}

.user-search-form label {
    min-width: 33px;
    text-align: left;
    font-weight: 600;
    color: #0A2C47;
}
.user-search-form-date-and-time {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.user-search-form-from, .user-search-form-to {
    flex-wrap: nowrap;
}


/* Travel planner details */

.travel-planner-notice {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    padding: 1rem;
    background-color: #f0f0f0;
    border-radius: 5px;
    flex-direction: column;
    gap: 1rem;

}
.travelplaner-details-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    background-color: #f9f9f9;
    flex-wrap: wrap;
    box-shadow: inset 0px 6px 20px 2px #7575752e;
}

.travelplaner-details-container-legs {
    display: flex;
    
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    background-color: #f9f9f9;
    flex-wrap: wrap;
    box-shadow: inset 0px 6px 20px 2px #7575752e;
    
}
.details-lineinfo-line:before {
    position: absolute;
    top: 0;
    height: 100%;
    background: rgb(0, 0, 0);
    content: "";
    width: 4px;
  }
  
.details-lineinfo-line {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2rem 1rem;
    border-radius: 10px;
    position: relative;
}

.details-lineinfo-line-dot {
    border-radius: 50%;
    z-index: 1;
    display: inline-block;
    border: 4px solid black;
  }

  .details-lineinfo-line-dot-start {
    border-radius: 50%;
    z-index: 1;
    display: inline-block;
    border: 4px solid black;
    position: absolute;
    top: 1rem;
  }

  .details-lineinfo-line-dot-end {
    border-radius: 50%;
    z-index: 1;
    display: inline-block;
    border: 4px solid black;
    position: absolute;
    bottom: 1rem;
  }

.details-leg-indicator {
    display: flex;
    align-items: center;
    width: 100%!important;
    text-align: center;
    border-radius: 10px;
    position: relative;
    justify-content: center;
}

.details-leg-indicator-dot-start:before {
    position: absolute;
    height: 4px;
    background: rgb(0, 0, 0);
    content: "";
right: 0;
  }

  .details-leg-indicator-dot-end:before {
    position: absolute;
    height: 4px;
    background: rgb(0, 0, 0);
    content: "";
    left: 0;
  }

.details-leg-indicator-dot {
    background-color: #f0c430;
    border-radius: 50%;
    z-index: 1;
    display: inline-block;
    border: 4px solid black;
  }

  .details-leg-indicator-dot-start {
    justify-content: flex-start;
    align-items: center;
    width: 100%!important;
    text-align: center;
    border-radius: 10px;
    position: relative;
    margin-left: 1rem;
}

.details-leg-indicator-dot-end {
    align-items: center;
    width: 100%!important;
    text-align: center;
    border-radius: 10px;
    position: relative;
    justify-content: flex-end;
    margin-right: 1rem;
}

.travelplaner-details-dot {
    background-color: #f0c430;
    border-radius: 50%;
    z-index: 1;
    display: inline-block;
    border: 4px solid black;
    margin: 0 0.5rem;
}



.travelplaner-trip-details-item {
    display: flex;

}

.travelplaner-trip-details-icon {
    width: 1.5rem;
    margin: auto 0;
}



.travelplaner-trip-details-text {
    text-align: left;
    word-wrap: break-word;
}

.travelplaner-meta-details {
    display: flex;
    flex-direction: column;
    margin: 3rem 1rem;
    flex: 2;
}

.travelplaner-meta-details-top {
    display: flex;
    font-size: 0.8rem;
    padding: 0.3rem;
    justify-content: center;
    align-items: center;
    margin: 0.3rem 0.3rem 0.4rem auto;
    width: 100px;
}

.travelplaner-trip-details {
    display: flex;
    flex-direction: column;
    margin: 3rem 1rem;
    flex: 3;
}

.travelplaner-trip-details p, .travelplaner-trip-details-walk p {
    font-size: 0.8rem;
    line-height: 1rem;
}




.travelplaner-hidden-details-line-name, .travelplaner-trip-details-text-quay {
    font-weight: 500;
}

.travelplaner-trip-details-icon-container {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    justify-content: center;
    flex-direction: column;
}

.travelplaner-details-left-time {
    margin-bottom: 0.2rem;
}

.travelplaner-trip-details-text-quay {
    text-align: left;
    transition: all 0.5s ease-in;
    
}

.travelplaner-details-mode-container {
    display: flex;
    width: 100%;
    gap: 1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.details-lineinfo-line-last {
    padding: 1rem 1rem 0;
    margin-bottom: 1rem;
  }

    .details-lineinfo-line-first {
        padding: 0 1rem 1rem;
        margin-top: 1rem;
    }

.travelplaner-details-time-container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    min-width: 50px;
    margin: 1rem 0;
}

.travelplaner-details-time-container p {
    font-weight: 600;
}

.travelplaner-trip-details-icon-container-walk {
    display: flex;
    align-items: center;
    font-weight: 500;
    background-color: #f0f0f0;
    margin: 1rem 0;
    border-radius: 5px;
    min-width: 60%;
    flex: 1;
    box-shadow: 0 0 20px 3px #7575752b;
    border: 1px solid #767c8930;
}

.travelplaner-trip-details-layout-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;

}

.details-lineinfo-line-dot-end-middle {
    border-radius: 50%;
    z-index: 1;
    display: inline-block;
    border: 4px solid black;
    position: absolute;
    bottom: 1rem;
}

.details-lineinfo-line-dot-start-first {
    top: 0;
}

.details-lineinfo-line-dot-start-last {
    top: 1rem;
}

.details-lineinfo-line-dot-end-first {
    bottom: 1rem;
}

.details-lineinfo-line-dot-end-last {
    bottom: 0;
}

.line-details-quay-container {
    display: flex;
    gap: 0.5rem;
}

.platform-text {
    font-size: 0.8rem;
    line-height: 1rem;
    font-weight: 500;
    color: #0A2C47;
}

.quay-name-container {
    display: flex;
    flex-direction: column;

}

.quay-name-container p {
    text-align: left;
    color: #0A2C47;

}

/* Stop details */

.stop-details-card {
    display: flex;
    flex-direction: column;
    background-color: #f0f0f0;
    border-radius: 5px;
    margin: 1rem 0;
    box-shadow: 0 0 20px 3px #7575752b;
    border: 1px solid #9fa3ab30;

}

.stop-details-card-content {
    display: flex;
    flex-direction: column;
  
    margin: 0 1rem 1rem 1rem;
    background-color: #f0f0f0;
    border-radius: 5px;

}

.stop-details-card p {
    text-align: left;
}

.stop-details-card h4 {
    text-align: left;
}

.stop-details-card-header {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px 5px 0 0;
    flex-wrap: wrap;
    background-color: #0055b7;
    gap: 1rem;
    padding: 0.5rem;

}

.stop-details-card-header img {
    width: 30px;
    height: 30px;

}

.stop-details-card-header h3 {
    font-size: 1rem;
    font-weight: 500;
    color: white;
    margin-block-start: 0.3rem;
    margin-block-end: 0.3rem;
    text-align: center;
}

.stop-details-card-meta {
    display: flex;
    flex-wrap: wrap;
    margin: 0.3rem 0;
    gap: 0 0.5rem;
    font-size: 1rem;
    font-weight: 400;
    color: #0A2C47;
}

.stop-details-card-meta p {
    font-size: 0.8rem;
    font-weight: 500;
}

.stop-details-card-code {
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
}

.stop-details-card-notice-text-show {
    display: flex;
    flex-wrap: wrap;
}

.stop-details-card-notice-text-hide {
    display: none;
}

.line-details-card {
    display: flex;
    flex-direction: column;
    background-color: #f0f0f0;
    border-radius: 5px;
    margin: 1rem 0;
    box-shadow: 0 0 20px 3px #7575752b;
    border: 1px solid #9fa3ab30;
    flex: 50%;
}

.line-details-card-header {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px 5px 0 0;
    gap: 1rem;

}

.line-details-card-header img {
    width: 30px;
    height: 30px;
    margin: 0.5rem;
}

.line-details-card-header h3 {
    font-size: 1rem;
    font-weight: 500;
    color: white;
    margin-block-start: 0.3rem;
    margin-block-end: 0.3rem;
    text-align: center;
    margin: 0.5rem;
}

.line-details-card-meta {
    display: flex;
    flex-wrap: wrap;
    margin: 0.3rem 1rem;
    gap: 0 0.5rem;
    font-size: 1rem;
    font-weight: 400;
    color: #0A2C47;
 
}
.line-details-card-meta-text {
    font-size: 0.8rem;
    font-weight: 500;

}

.line-details-stop-list-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem;
    gap: 0.3rem;
    max-height: 350px;
    overflow-y: auto;
}


.travelplaner-search-notice-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    padding: 1rem;
    background-color: #f0f0f0;
    border-radius: 5px;
    flex-direction: column;
    gap: 1rem;
}

.open-travelplaner-details {
    display: flex;
    padding: 0.5rem;
    background-color: #0A2C47;
    color: white;
    transition: all 0.5s ease;
}

.open-travelplaner-details:hover {
    cursor: pointer;
    background-color: #435e77;
}

.travelplaner-meta-details-text {
    color: white;
}

.travelplaner-btn {
    display: flex;
    margin: 0;
    padding: 0.5rem;
    border: none;
    background-color: unset;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    color: #0A2C47;
    gap: 0.5rem;
}

.travelplaner-btn p {
    color: white;
    font-weight: 500;
}


.travelplaner-btn:hover {
    background-color: #435e77;
    text-decoration: underline;
    color: white;
}

.travelplaner-btn-icon {
    width: 1rem;
    
}

.travelplaner-details-button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    background-color: #435e77;
    width: 100%;
}


.travelplanner-input-form-group {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.travelplaner-page-cursor-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin: 1rem;
}



  /* media class for smaller screens */
  @media only screen and (max-width: 1024px) {
    .travel-planner-main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90%;
        margin-top: 1rem;
        margin: auto;
    }
  }

    /* media class for smaller screens */
@media only screen and (max-width: 680px) {
    .travelplaner-details-button-container {
        flex-wrap: wrap;
        gap: 0.5rem;
    }

    .userQuery {
        margin: 0.5rem 1rem;
        font-size: 1rem;
        gap: unset;
    }

    .react-datepicker-wrapper .react-datepicker__input-container input[type="text"] {
        font-size: 1rem;
    }

    .userQuery select, .userQuery input {
        font-size: 1rem;
    }

    .user-search-form {
        gap: 0.5rem;
    }

    p, .travelplaner-trip-details p {
        font-size: 0.8rem;
    }
    .travel-planner-main {
        width: 98%;
    }
    .travelplaner-row {
        margin-top: 0.5rem;
    }
    .travelplaner-from-container, .travelplaner-to-container {
        margin: 0.5rem;
        width: 100%;
        justify-content: space-between;
    }
    .travelplaner-legs-container {
        padding: 0.7rem 0.7rem 1rem 0.7rem;
    }
    .travelplaner-from-label, .travelplaner-to-label, .travelplaner-start-label, .travelplaner-end-label {
        font-size: 12px;
    }
    .travelplaner-from, .travelplaner-to, .travelplaner-start, .travelplaner-end, .travelplaner-from-name, .travelplaner-to-name {
        font-size: 0.9rem;
    }
    .travelplaner-meta-container {
        
        gap: 0.5rem;
    }
    .travelplaner-departure-time, .travelplaner-departure-from-to {
        gap: 0.2rem;
    }
    .travelplaner-from-time, .travelplaner-to-time {
        font-size: 12px;
    }
    .travelplaner-start, .travelplaner-end {
        font-size: 1rem;
    }
    .input-top form {
        padding: 0.5rem;
    }
    .trip-detail, .leg-detail {
        margin: 1rem 0;
    }
    .leg-detail div {
        margin: 2px 0;
    }
    .travelplaner-details-time-container {
        min-width: 45px;
    }
    
    .travelplaner-trip-details-item {
        gap: 0rem;
    }

  
    .travelplaner-btn {
        padding: 0.3rem 0.3rem;

        justify-content: end;
        gap: 0.2rem;

    }

    .travelplaner-btn-icon {
        width: 1rem;
    }




}

@media only screen and (max-width: 470px) {

    .travelplaner-trip-details {
        margin: 1rem 0.5rem;
    }


    .leg-time-container {
        margin-top: 0rem;
    }

    button, input[type="submit"] {
        padding: 0.5rem;
    }

    .travel-planner-page {
        margin-top: 1rem;
    }

    input[type="text"], input[type="number"], input[type="email"], input[type="password"], input[type="date"], input[type="tel"], input[type="url"], input[type="search"], input[type="number"], input[type="range"], input[type="color"], input[type="radio"], select, textarea {
        width: 100%;
        padding: 0.5rem 0.5rem;

        box-sizing: border-box;
        border: 2px solid #ccc;
        border-radius: 4px;
        font-size: 0.8rem;
        color: #0A2C47;
    }

    input[type="time"] {
        padding: 0.3rem;
        margin: 0rem;
        border-radius: 0;
        border-bottom: 2px solid #0A2C47;
    }

    .userQuery select, .userQuery input, .react-datepicker-wrapper .react-datepicker__input-container input[type="text"] {
        padding: 0.3rem;
        margin: 0rem;
        border-radius: 0;
        border-bottom: 2px solid #0A2C47;
    }

    .user-search-form {
        gap: 0.3rem;
        margin: 0.3rem 0;
    }

    .input-top form {
        gap: 0.5rem;
    }
    
}

@media only screen and (max-width: 376px) {
    
    .travelplaner-meta-container {
        flex-direction: column;
        gap: 0;
        border-radius: 5px 5px 0 0;
    }

.travelplaner-to-container {
        margin:  0rem 0 0.3rem 0;
        width: 100%;
     
    }
    .travelplaner-from-container {
        margin: 0.3rem 0 0 0;
        width: 100%;
     
    }

    .travelplaner-from, .travelplaner-from-label, .travelplaner-to-name, .travelplaner-from-name, .travelplaner-to, .travelplaner-to-label, .travelplaner-from-time, .travelplaner-to-time {
        font-size: 0.8rem;
        margin: 0.2rem 0.3rem 0 0.3rem;
    }
    .travelplaner-start, .travelplaner-end {
        font-size: 0.8rem;
        margin: 0.2rem 0.3rem 0 0;
    }

    .travelplaner-details-line-number p {
        font-size: 0.8rem;
    }

}