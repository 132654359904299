.favorite-stops-container {
  display: flex;
  flex-direction: column;

  align-items: center;
  width: 250px;
  margin-top: 2rem;
}

.favorite-stop-child {
  display: flex;
  flex-direction: row;

  align-items: center;
  width: 100%;
  margin-top: 2rem;
}

.favorite-stop-list-icon {
    background-color: #0A2C47;
    padding: 0.5rem;
    border-radius: 10px;
    margin-right: 1rem;
}

.favorite-stop-list-icon img {
  width: 30px;
  height: 30px;
}