.login-container {
    display: flex;
    flex-direction: column;
    max-width: 400px;

    background-color: var(--bg-color-primary);

}

.login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 400px;
    margin: 1rem auto;
}

.login-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
 
    margin: 2rem 2rem;
}