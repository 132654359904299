body {
  font-size: 0.8rem;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  height: 100vh;
}

footer {
  padding: 20px;
  text-align: center;
}

h1, h2, h3 {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: #1c1c1c;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  color: #1c1c1c;
}

a {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  color: #1c1c1c;
  letter-spacing: 0.5px;
  font-size: 0.8rem;

  text-decoration: none;
  transition: all 0.3s ease;
}

nav a:hover {
  text-decoration: none;
  border-bottom: 2px solid #ffffff;
  
}


h4 {
  margin-block-start: 0.3rem;
  margin-block-end: 0.3rem;
}

p {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #474747;
  letter-spacing: 0.5px;
  font-size: 0.8rem;
  line-height: 1.2rem;
  margin-block-start: 0.2rem;
  margin-block-end: 0.2rem;
}

.smaller-text {
  font-size: 0.7rem;
}

.text-white {
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #0A2C47;
  color: white;
  padding: 0.5rem;
  text-align: center;
}


.App-header nav ul li {
  margin: 0 15px;
}

.App-header nav ul li a {
  color: white;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.4rem;
}

.App-header h1 {
  font-family: "Megrim", system-ui;
  font-weight: 400;
  font-style: normal;
  margin:auto;
  color: white;
  font-size: 3rem;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.menu-icon {
  display: none;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 25px;
  background: transparent;
  border: none;
  z-index: 10;
}

.menu-icon div {
  width: 100%;
  height: 3px;
  background-color: #333;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.mapContainer {
  height: 100vh;
  width: 100%;
}

.btn-login {
  margin: 0;
  color: white;
  border: none;
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 5px;
}

.btn-login:hover {
  background-color: unset;
  color: #d1d1d1;
}

.filter-menu {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  z-index: 1000;
  display: flex;
  border-radius: 10px 0 0 10px;
  transition: all 1s ease;
}

.type-selector {
  order: 1;
  justify-content: start;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  background-color: #0A2C47;
  gap: 0.3rem;
}

.type-selector button {
  display: block;
  margin-bottom: 5px;
}

.filter-icon-container {
  order: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  background-color: #0A2C47;
  transition: all 0.3s ease;
}

.filter-icon-container:hover {
  background-color: #35393f;
  cursor: pointer;
}

.filter-icon-image {
  width: 30px;
  height: 30px;

}

.operator-list {
  display: none;
  max-height: 300px;
  overflow-y: auto;
}

.operator-list.active {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.operator-item {
  margin-bottom: 5px;
  display: flex;
  justify-content: flex-start;
}

.operator-list {
  max-height: 300px;
  overflow-y: auto;
}

/* Map marker popups */

.pop-up-logos a img {
  width: 50px;
  margin: 0 5px;
}

.pop-up-logos a:hover {
  transform: none;
  text-decoration: none;
}

.android-logo {
  width: 150px;
}

@media only screen and (max-width: 600px) {
  .App-header nav ul li a {
    margin: 0 5px;
    font-size: 15px;
  }
}

@media only screen and (max-width: 500px) {
  .App-header {
    padding: 0.2rem;
  }
  .App-header nav ul {
    margin: 0.3rem 0 0.2rem 0;
  }

  .App-header h1 {
    font-size: 2.5rem;
  }
}

@media only screen and (max-width: 400px) {
  .App-header {
    padding: 0.2rem;
  }
  .App-header nav ul li {
    margin: 0 0.3rem;
  }
}